<template>
  <a-modal
    class="qrcode-range-modal"
    title="水印设置"
    centered
    :visible="qrcodeVisible"
    :width="840"
    okText="保存"
    @cancel="handledClose('close')"
    @ok="handledClose('ok')"
  >
    <div class="qrcode-box">
      <div class="left-box">
        <img
          class="qrcode-template"
          src="@/assets/images/diyForm/qrcode_template.png"
          alt=""
          :style="qrcodeTemplateStyle.val"
        />
        <img class="template" src="@/assets/images/diyForm/template.png" alt="" />
      </div>
      <div class="right-box">
        <div class="right-title">二维码水印</div>
        <div class="form">
          <a-form-item label="大小：" required :labelCol="{ span: 4, offset: 2 }">
            <a-select v-model:value="form.qrcodeSize" style="width: 100%;" @change="qrcodeSizeChange">
              <a-select-option v-for="item in options.qrcodeSizeOptions" :key="item.id" :value="item.id">{{
                item.qrcodeSize
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="位置：" required :labelCol="{ span: 4, offset: 2 }">
            <div class="position-box">
              <div
                @click="selectPosition(item)"
                class="position-item"
                v-for="item in options.qrcodePositionOptions"
                :key="item.id"
              >
                <img :src="form.qrcodePositionType == item.id ? item.active : item.default" alt="" />
                <div :class="['item-info', form.qrcodePositionType == item.id && 'active-info']">{{ item.info }}</div>
              </div>
            </div>
          </a-form-item>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script setup>
import { defineProps, defineEmits, reactive, defineExpose } from 'vue'
defineProps({
  qrcodeVisible: {
    type: Boolean,
    default: false
  },
  qrcodeForm: {
    type: Object,
    defalut: () => {
      return {}
    }
  }
})
const emit = defineEmits(['update:qrcodeVisible', 'handleOk'])

const form = reactive({
  qrcodeSize: 2,
  qrcodePositionType: 1
})

const qrcodeTemplateStyle = reactive({
  val: {
    width: '48px',
    height: '48px',
    top: '8px',
    left: '8px'
  },
  position: [
    ['left', 'top'],
    ['right', 'top'],
    ['left', 'bottom'],
    ['right', 'bottom']
  ],
  size: [42, 48, 54, 60]
})

const options = reactive({
  qrcodeSizeOptions: [
    {
      id: 1,
      qrcodeSize: '20*20mm'
    },
    {
      id: 2,
      qrcodeSize: '25*25mm'
    },
    {
      id: 3,
      qrcodeSize: '30*30mm'
    },
    {
      id: 4,
      qrcodeSize: '35*35mm'
    }
  ],
  qrcodePositionOptions: [
    {
      id: 1,
      active: require('@/assets/images/diyForm/active01.png'),
      default: require('@/assets/images/diyForm/default01.png'),
      info: '左上'
    },
    {
      id: 2,
      active: require('@/assets/images/diyForm/active02.png'),
      default: require('@/assets/images/diyForm/default02.png'),
      info: '右上'
    },
    {
      id: 3,
      active: require('@/assets/images/diyForm/active03.png'),
      default: require('@/assets/images/diyForm/default03.png'),
      info: '左下'
    },
    {
      id: 4,
      active: require('@/assets/images/diyForm/active04.png'),
      default: require('@/assets/images/diyForm/default04.png'),
      info: '右下'
    }
  ]
})

//二维码大小变化
const qrcodeSizeChange = val => {
  qrcodeTemplateStyle.val.width = qrcodeTemplateStyle.val.height = qrcodeTemplateStyle.size[val - 1] + 'px'
}

//二维码位置
const selectPosition = val => {
  form.qrcodePositionType = val.id
  const newVal = {
    width: qrcodeTemplateStyle.val.width,
    height: qrcodeTemplateStyle.val.height
  }
  newVal[qrcodeTemplateStyle.position[val.id - 1][0]] = newVal[qrcodeTemplateStyle.position[val.id - 1][1]] = '8px'
  qrcodeTemplateStyle.val = newVal
}

const rest = () => {
  form.qrcodePositionType = 1
  form.qrcodeSize = 2
}

const handledClose = type => {
  if (type == 'close') {
    rest()
    return emit('update:qrcodeVisible', false)
  }
  emit('update:qrcodeVisible', false)
  emit('handleOk', { ...form })
}

const init = val => {
  if (typeof val == 'object') {
    qrcodeSizeChange(val.qrcodeSize)
    selectPosition({ id: val.qrcodePositionType })
    form.qrcodeSize = val.qrcodeSize
    form.qrcodePositionType = val.qrcodePositionType
  }
}

defineExpose({
  init
})
</script>
<style lang="less" scoped>
.qrcode-box {
  display: flex;
  .left-box {
    width: 428px;
    height: 606px;
    position: relative;
    .template {
      width: 100%;
    }
    .qrcode-template {
      position: absolute;
    }
  }
  .right-box {
    flex: 1;
    .right-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
      padding-left: 24px;
    }
    .form {
      margin-top: 24px;
      :deep(.ant-form-item-control-wrapper) {
        flex: 1;
      }

      :deep(.ant-form-item) {
        .ant-form-item-label {
          margin-left: 24px;
          display: flex;
        }
      }
      .position-box {
        display: flex;
        flex-wrap: wrap;
        .position-item {
          margin-right: 24px;
          margin-bottom: 8px;
          cursor: pointer;
          img {
            width: 100px;
            height: 120px;
            display: block;
          }

          .item-info {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            text-align: center;
            margin-top: 4px;
          }

          .active-info {
            color: #c3161c;
          }
        }
      }
    }
  }
}
</style>
